@import "search.scss";

.header-filters {

    span:first-of-type {
        cursor: pointer;
    }

    i:first-of-type {
        margin-right: 3px;
        cursor: pointer;

        &.selected {
            color: $brand-blue;
        }
    }

    .filter-list-background {
        z-index: 999;
        background: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .filters {
        position: absolute;
        height: 365px;
        width: 300px;
        background: $brand-white;
        z-index: 9999;
        border: solid 1px $brand-input-border;
        left: 0;
        top: 35px;
        padding: 0 0 37px;

        .close {
            top: 10px;
            right: 15px;
            margin: 0;
            font-size: 14px;
        }

        .list {
            overflow: auto;
            height: 282px;
            padding: 0 10px 15px;
            margin-top: 10px;
        }

        .filter {
            cursor: pointer;
            padding: 0;
            white-space: normal;
         
            input {
                vertical-align: middle;
                margin-right: 5px;
            }
        }

        .search-container {
            width: 267px;
            font-size: 12px;
            padding: 3px 6px;
            margin: 10px 10px 0;
            float: none;
            line-height: 23px;

            input {
                padding: 3px 6px;
                line-height: 12px;
            }

            i {
                display: block;
                margin-top: 7px;
            }
        }

        footer {
            padding: 10px;
            text-align: right;
            background-color: $brand-modal-footer;

            button {
                font-size: 12px;
                margin-right: 10px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        &.filter-header {
            cursor: pointer;
        }
    }
}
