﻿// @import "/node_modules/react-day-picker/lib/style";

/* screen max-widths */

$mobile: 414px;
$tablet: 1024px;

/* colours */

$pc-blue: #0283ff;
$hover: #f8f8f8;

$text-color: #3d3d3d;

$background-color: #ebebed;
$border-grey: #dde3e8;

$utility-red: #d64f57;
$utility-yellow: #EF9400;
$utility-green: #8dc754;
$utility-grey: #868686;
$utility-light-grey: #a9a9a9;
$utility-icon-grey: #bcbcbc;
$utility-icon-dark-grey: #868686;
$utility-navy: #2370ab;


$black: #000;
$white: #fff;

$input-border-color: #dde3e8;

$label-color: #3d3d3d;
$label-disabled: #d9dde0;
$input-disabled: #f5f5f5;

/* typography */
$font-default: 'Roboto', sans-serif;
$font-medium: 'Roboto Medium', sans-serif;

$font-light-weight: 300;
$font-default-weight: 400;
$font-size-default: 14px;
$font-size-title: 40px;
$font-size-subtitle: 24px;
$font-size-details: 14px;


/* old stuff */

$body-background: #fff;
$content-background: #fff;
$headercolor: #fff;
$footercolor: #fff;
$input-color: #5f5f5f;

$input-border: solid 1px $input-border-color;
$input-padding: 10px;
$select-padding: 10px 20px 10px 10px;
$button-color: #fff;
$disabled-background-color: $input-border-color;
$error-color: $utility-red;
$font-color-default: #868686;
$font-color-title: #3d3d3d;
$nav-selected-color: #67c8e0;
$rejected-color: $utility-red;
$approval-pending-color: $utility-yellow;
$approved-color: $utility-green;
$font-color-template-name: #3d3d3d;
$private-organizer-background-color: #fff;
$payment-page-header: $pc-blue;
$draft-color: $pc-blue;
$closed-color: grey;


$input-width-mini: 70px;
$input-width-small: 100px;
$input-width-medium: 200px;
$input-width-large: 60%;
$select-width-large: 60%;
$input-width-max: 100%;
$textarea-min-height: 90px;

$manatee: #979797;
$gray29: #4a4a4a;

$animation-background: #fff;
$modal-animation-background: #e6e7e8;

$table-row-hover: $hover;

@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@mixin font-default() {
    font-family: $font-default;
    font-weight: $font-default-weight;
    font-size: $font-size-default;
    color: $font-color-default;
}

@mixin font-title() {
    font-family: $font-default;
    font-weight: $font-light-weight;
    font-size: $font-size-title;
    color: $font-color-title;
}

@mixin font-sub-title() {
    font-family: $font-default;
    font-weight: $font-light-weight;
    font-size: $font-size-subtitle;
    color: $font-color-title;
}

@mixin font-details() {
    font-family: $font-default;
    font-weight: $font-default-weight;
    font-size: $font-size-details;
    color: $font-color-default;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}

@mixin border-radius-specific($topleft, $topright, $bottomright, $bottomleft) {
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
     -moz-border-radius: $topleft $topright $bottomright $bottomleft;
          border-radius: $topleft $topright $bottomright $bottomleft;
}

@mixin box-shadow($horizontal, $vertical, $blurradius, $spreadradius, $color) {
    -webkit-box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
    -moz-box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
    box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
}

@mixin no-list-style() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin link() {
    color: $headercolor;
    text-decoration: none;
    cursor: pointer;
    font-weight: $font-light-weight;
}

// Internet Explorer 10+ only
@mixin ie-only() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../../fonts/MaterialIcons-Regular.woff2) format('woff2'),
       url(../../fonts/MaterialIcons-Regular.woff) format('woff'),
       url(../../fonts/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons  {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}