﻿@import "brand-variables";
@import "search.scss";
@import "districtselect.scss";
@import "loader.scss";
@import "./reports/UnseenReportRequests.scss";

div.main > header,
#site-header > header,
footer.page {
    background: $brand-blue;
    position: relative;
    min-height: 54px;
}

.goog-te-spinner {
    /* google translator has a spinner that is consuming cpu all the time spinning hidden!!
    */
    display: none !important;
}

header {
    .menu-background {
        z-index: 999;
        background: $brand-white;
        opacity: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: default;

        &.admin-menu-background {
            background: transparent;
            opacity: 1;
        }
    }

    .notifications-progress {
        position: relative;
        display: inline-block;
        padding: 0 10px 10px 10px;
        margin-left: 10px;

        .slider {
            position: absolute;
            width: 35px;
            height: 5px;
            bottom: 0;
            left: 0;
            overflow-x: hidden;

            .line {
                position: absolute;
                opacity: 0.6;
                background: white;
                width: 150%;
                height: 5px;
            }

            .subline {
                position: absolute;
                background: $brand-blue;
                height: 5px;
            }

            .inc {
                left: 0;
                width: 100%;
                transform-origin: top left;
                animation: increase 2s infinite;
            }
            .dec {
                left: 0;
                width: 100%;
                transform-origin: top left;
                animation: decrease 2s 0.5s infinite;
            }
        }

        @keyframes increase {
            from {
                transform: translateZ(0) translateX(-5%) scaleX(0.05);
            }
            to {
                transform: translateZ(0) translateX(130%) scaleX(1);
            }
        }
        @keyframes decrease {
            from {
                transform: translateZ(0) translateX(-80%) scaleX(0.8);
            }
            to {
                transform: translateZ(0) translateX(110%) scaleX(0.1);
            }
        }
    }

    .notifications {
        width: 425px;
        background: $brand-white;
        padding: 20px;
        position: absolute;
        top: 70px;
        right: 0;
        z-index: 1000;
        @include border-radius(3px);
        @include box-shadow(0px, 2px, 14px, 0, rgba(0, 0, 0, 0.15));
        color: $brand-copy-grey;
        cursor: default;

        .loader {
            display: block;
            max-width: 48px;
            max-height: 48px;
            margin: 0 auto;
            position: relative;
        }

        h3 {
            font-size: 1.2em;
            font-weight: bold;
            padding: 0;
            margin: 0;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            overflow-y: auto;
            max-height: 410px;
            display: block;
            li {
                display: block;
                margin: 0;
                padding: 10px 0;
                border-bottom: dotted 1px $brand-light-grey;
                margin-right: 3px; // for scrollbar
                .icon {
                    display: block;
                    float: left;
                    min-height: 28px;
                    min-width: 28px;
                    opacity: 0.7;
                    text-align: center;
                    margin-right: 7px;
                    i {
                        margin: 0;
                        vertical-align: unset;
                    }
                }

                span {
                    cursor: default;
                }

                &:last-of-type {
                    border-bottom: 0;
                }
                span.title {
                    font-weight: bold;
                    color: $brand-copy-black;
                    font-size: 0.9em;
                }
                span.time {
                    display: block;
                    font-size: 0.8em;
                    color: $brand-copy-grey;
                }
                &.failed {
                    i,
                    span.state {
                        color: $brand-error;
                    }
                }
                &.completed {
                    i,
                    span.state {
                        color: $brand-published-color;
                    }
                }
                &.started,
                &.pending {
                    i,
                    span.state {
                        color: $brand-blue;
                    }
                }

                &.partially-completed {
                    i,
                    span.state {
                        color: $brand-orange;
                    }
                }

                &.separator {
                    border-bottom: solid 1px lightgray;
                    height: 1px;
                    padding: 0;
                    margin-top: -2px;
                }
            }
        }
    }

    .profile-selection {
        width: 320px;
        background: $brand-white;
        padding: 15px;
        position: absolute;
        top: 60px;
        left: 0;
        z-index: 1000;
        cursor: default;
        @include border-radius(3px);
        @include box-shadow(6px, 6px, 15px, 0, #ccc);

        .list {
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 600px;
        }

        .buttons {
            padding-top: 10px;
            margin-top: 10px;
            border-top: solid 1px #dedede;
            text-align: right;
            padding-bottom: 10px;
            a.sign-out,
            a.dashboard {
                display: inline-block;
                background: $brand-dark-blue;
                font-size: 14px;
            }
            a:hover {
                border: 0;
            }
        }

        .profile {
            display: block;
            white-space: nowrap;
            padding: 10px;
            @include border-radius(4px);
            cursor: pointer;
            margin-bottom: 0px;
            color: $brand-copy-black;
            &:visited {
                color: $brand-copy-black;
            }
            > * {
                vertical-align: middle;
            }

            img,
            .logo-placeholder {
                max-width: 45px;
                max-height: 45px;
                display: inline-block;
                @include border-radius(23px);
                margin: 0;
                margin-right: 5px;
            }

            .middle {
                display: inline-block;
                width: 210px;
                line-height: 16px;
                span {
                    display: block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 0.8em;
                    color: $brand-copy-grey;
                    overflow: hidden;

                    &:nth-child(1) {
                        color: $brand-blue;
                        font-size: 1em;
                    }
                }
            }

            &.selected {
                i {
                    display: inline-block;
                    color: $brand-green;
                    width: 13px;
                    height: 13px;
                }
            }

            &:hover {
                background: $brand-blue;
                color: $brand-white;

                .middle span {
                    color: $brand-white;
                }
            }
        }

        .district-select {
            background: $brand-blue;
            color: $brand-white;
            padding: 0;
            width: 127px;
            @include border-radius(4px);
            display: inline-block;
            margin-right: 8px;

            .select {
                background: none;
                border: none;
                color: $brand-white;
                padding: 10px;
                min-width: 0;
                min-height: 0;
                text-align: center;
                width: auto;

                span {
                    position: relative;
                    left: 0;
                    font-size: 14px;
                    display: inline;
                }
            }
        }

        .district-list {
            top: 158px;
            right: 198px;
            height: 410px;
            width: 600px;
            z-index: 1000;
            @include box-shadow(6px, 6px, 15px, 0, #ccc);

            &.pc-admin {
                width: 975px;
                right: 0;
            }

            .districts {
                max-height: 330px;
            }

            .switch-label {
                font-size: 12px;
            }

            @media (max-width: 989px) {
                left: auto;
                right: 5px;
                width: auto !important;
                overflow: hidden;

                header {
                    padding-bottom: 30px;
                }

                ul.list-header,
                .district ul {
                    width: 340px;
                }

                ul.list-header,
                .districts ul {
                    li:nth-child(n + 4) {
                        display: none;
                    }
                }

                ul.list-header,
                .districts ul {
                    li:nth-child(-n + 3) {
                        max-width: 100px;
                        text-overflow: ellipsis;
                    }
                }
            }

            @media (min-width: 990px) and (max-width: 1025px) {
                left: auto !important;
                right: -95px !important;
            }
        }

        @media (max-width: 989px) {
            left: auto;
            right: 5px;
        }
    }

    .submenu {
        > div {
            .nav-admin {
                @include transition(max-height 0.4s);
                overflow: hidden;
                max-height: 500px;
                box-sizing: border-box;
            }
            &.hidden {
                display: block !important;
                .menu-background {
                    display: none;
                }
                .nav-admin {
                    max-height: 0;
                    visibility: hidden;
                }
            }
        }
    }

    .notifications,
    .profile-selection {
        &.animation {
            @include transition(max-height 0.4s);
            overflow: hidden;
            max-height: 500px;
        }
        &.animation-closed {
            overflow: hidden;
            max-height: 0;
            padding: 0;
        }
    }
}

nav {
    font-size: 13px;
    font-weight: normal;

    > ul {
        @include no-list-style;
        display: flex;

        &.layout-content {
            padding: 0;
            margin-top: 0;
            margin-bottom: 0;
            width: 100vw;
        }

        > li {
            display: block;
            align-self: center;
        }

        li.mobile {
            // menus for mobile

            text-align: center;
            padding: 15px 0;
            position: relative;

            .mobile-menu {
                position: absolute;
                top: 44px;
                left: 15px;
                width: 170px;
                background: #fff;
                border: solid 1px $brand-input-border;
                -webkit-box-shadow:
                    0 3px 6px rgba(0, 0, 0, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.15);
                -moz-box-shadow:
                    0 3px 6px rgba(0, 0, 0, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.15);
                box-shadow:
                    0 3px 6px rgba(0, 0, 0, 0.1),
                    0 3px 6px rgba(0, 0, 0, 0.15);
                text-align: left;
                padding: 15px;
                z-index: 9992;

                a,
                .submenu span {
                    display: inline-block;
                    padding: 15px 0;
                    font-size: 1.1em;
                    color: $brand-copy-darkgrey;
                    margin: 0;
                }

                li,
                div {
                    display: block;
                    color: $brand-copy-darkgrey;
                    text-align: left;
                    padding: 0;
                    margin: 0;
                }
                .submenu span {
                    i {
                        float: right;
                        clear: right;
                        margin: 0 5px;
                        padding: 0;
                    }
                }

                .nav-admin {
                    background-color: $brand-white;
                    border: solid 1px $brand-input-border;
                    -webkit-box-shadow:
                        0 3px 6px rgba(0, 0, 0, 0.1),
                        0 3px 6px rgba(0, 0, 0, 0.15);
                    -moz-box-shadow:
                        0 3px 6px rgba(0, 0, 0, 0.1),
                        0 3px 6px rgba(0, 0, 0, 0.15);
                    box-shadow:
                        0 3px 6px rgba(0, 0, 0, 0.1),
                        0 3px 6px rgba(0, 0, 0, 0.15);
                    padding: 10px 16px;
                    margin-top: -15px;
                }

                li,
                i,
                span {
                    color: $brand-copy-darkgrey;
                    text-align: left;
                    float: none;
                }
                .unseen-report-requests {
                    color: $brand-white;
                    float: none;
                    display: inline-block;
                    margin-left: 10px;
                    text-align: center;
                    padding: 1px 3px;
                    font-size: 1.02em;
                }
            }

            i {
                display: block;
                padding: 0 15px;
                float: left;
                font-size: 18px;
                color: #fff;
                margin-top: 8px;
                cursor: pointer;
            }

            .logo-wrapper {
                display: block;
                float: right;
            }

            .logo-placeholder {
                float: right;
            }

            .site-logo {
                width: 180px;
            }
        }

        li {
            // menus for desktop
            vertical-align: middle;
            white-space: nowrap;

            &.submenu,
            & > div.submenu,
            a {
                display: inline-block;
                padding: 5px 5px;
                font-weight: normal !important;
                cursor: pointer;
                border-bottom: 2px solid transparent;
                &:hover {
                    border-bottom: 2px solid #fff;
                }

                margin: 0 5px;

                &.active {
                    border-bottom: 2px solid #fff;
                }
            }

            .modal-content-container {
                color: black;
            }

            a {
                display: block;
            }

            a,
            a:hover,
            a:visited,
            > span,
            > div {
                @include link;
                position: relative;
                font-weight: normal;

                &.nav-link.nav-dashboard {
                    display: inline-block;
                }

                i {
                    margin-left: 5px;
                    vertical-align: bottom;
                }
            }

            > span nav ul li a.nav-home {
                position: relative;
            }

            .logo-wrapper,
            .logo-placeholder {
                margin: 0 15px;
            }

            .logo-wrapper {
                display: inline-block;
                background: $brand-white;
                position: relative;
                overflow: hidden;

                width: 50px;
                height: 50px;
                @include border-radius(50%);
                cursor: pointer;
                text-align: center;

                > img {
                    max-width: 50px;
                    max-height: 50px;
                    margin: 0;
                }
            }

            .logo-placeholder {
                display: inline-block;
                width: 50px;
                height: 50px;
                cursor: pointer;
                background: #ccc;
                @include border-radius(25px);
                text-align: center;
                font-weight: normal;
                color: $brand-white;
                @include noselect();
                > span {
                    font-size: 24px;
                    padding: 10px 0;
                    display: inline-block;
                }
            }
        }

        li.logo {
            // pc logo
            padding: 0;
            height: 80px;
            margin: 0;
            text-align: center;
            margin-right: 15px;

            a {
                padding: 0;
                margin: 0;
                padding: 18px 0 0 0;

                &:hover {
                    border-bottom: solid 2px transparent;
                }
                img {
                    width: 180px;
                    margin: 0;
                    vertical-align: middle;
                }
            }
        }

        li.user-info {
            color: $brand-white;
            float: right;
            text-align: left;
            position: relative;
            padding: 10px 0;
            height: 60px;
            margin-left: auto;

            div.current-user {
                display: inline-block;
                > * {
                    vertical-align: middle;
                }

                > div {
                    display: inline-block;
                    cursor: pointer;
                    height: 50px;

                    &.district {
                        height: 30px;
                    }

                    > p {
                        margin: 2px 0 3px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #fff;

                        &:not(:first-of-type) {
                            font-size: 11px;
                            color: #b3d7ff;
                            margin: 0 0 3px;
                        }

                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }

            > i {
                min-height: 20px;
                vertical-align: middle;
            }

            .notifications-progress {
                vertical-align: text-top;
            }

            > i,
            .notifications-progress i {
                font-size: 20px;
                height: 20px;
                cursor: pointer;
                margin-right: 15px;

                &:last-of-type {
                    margin: 0;
                }
            }
        }

        @media (max-width: 989px) {
            // for mobile displays
            li.user-info {
                .current-user > div.org-name {
                    display: none;
                }

                .back-to-dashboard {
                    display: none;
                }

                .notifications-progress {
                    display: none;
                }
            }

            li.logo {
                flex-grow: 1;
                flex-shrink: 0;
                margin-right: 0;
            }

            li.menu {
                display: none;
            }
        }
        @media (min-width: 990px) {
            li {
                &.logo {
                    margin-left: 0;
                    text-align: left;
                }

                &.mobile {
                    display: none;
                }
            }
        }
    }
}

.profile-selection-modal {
    .modal-content-container {
        height: 400px;

        .profile {
            max-width: 400px;

            > div {
                > div {
                    width: 330px;
                    right: 0;
                }
            }

            a,
            a:hover,
            a:visited,
            > span,
            > div {
                @include link;
                color: $brand-copy-grey;
            }
        }
    }
}

nav ul li.nav-save-and-exit,
nav ul li.nav-preview-event {
    display: block;
    float: right;
    padding-right: 25px;
    padding-left: 50px;
    cursor: pointer;
}

nav ul li.nav-save-and-exit {
    background: rgba(25, 185, 228, 1) url("../../img/editing/save-exit.png") no-repeat 20px center;
}

nav ul li.nav-preview-event {
    padding: 0;
    background: url("../../img/editing/preview.svg") no-repeat 10px center;
}

nav ul li.nav-preview-event a {
    display: block;
    padding: 20px 25px 20px 50px;
}

nav ul li a.nav-link.nav-dashboard {
    display: inline-block;
}

nav ul li > span nav ul li a.nav-home {
    position: relative;
}

nav a.logout {
    float: right;
}

nav #myapprovals-notification-wrapper {
    position: relative;
}

nav #myapprovals-notification-wrapper #events-notification {
    position: absolute;
    top: 0;
    left: -20px;
}

ul.district span#district-notification-wrapper,
ul.forms span#form-notification-wrapper,
ul.gst span#gst-report-notification-wrapper,
ul.storage span#storage-notification-wrapper,
ul.response span#response-documents-notification-wrapper,
ul.templates span#templates-notification-wrapper,
ul.forms span#forms-notification-wrapper,
ul.process-overview span#process-notification-wrapper,
ul.myapprovals span#myapprovals-notification-wrapper {
    border-bottom: solid 3px $brand-white;
    padding-bottom: 5px;
}

ul.nav-admin {
    background: $brand-blue;
    position: absolute;
    margin-left: -10px;
    z-index: 1000;
    width: 250px;
    padding: 15px 0 0;
    top: 10px;

    li {
        float: none;
        display: block;
        padding: 5px 15px;

        a {
            display: inline-block;
            padding: 5px;
        }

        &:first-of-type {
            padding-top: 5px;
        }

        &:last-of-type {
            padding-bottom: 15px;
        }
    }

    &.show {
        display: block;
    }
}

.menu-arrow {
    padding-right: 15px;
    background: url("../../img/menuarrow.png") no-repeat 100%;
    cursor: pointer;
}

.hidden,
.hide {
    display: none !important;
}

.show {
    display: block;
}

img.yes,
img.no {
    max-height: 20px;
    max-width: 20px;
    margin-top: 10px;
    display: none;
}

span.notification-wrapper {
    display: inline-block;

    .circle {
        background: #ec403b;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        float: right;
        margin: 4px 0 0 5px;
        position: absolute;
        top: 5px;
        right: 0;
    }
}

#hideaway-header {
    justify-content: left;

    @media (min-width: 768px) {
        justify-content: center;
    }

    display: flex;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
}

#hideaway-header.header-hidden {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        transform: rotate(-15deg);
    }

    2% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    4% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    6% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    8% {
        -webkit-transform: rotate(-22deg);
        transform: rotate(-22deg);
    }

    10% {
        -webkit-transform: rotate(22deg);
        transform: rotate(22deg);
    }

    12% {
        -webkit-transform: rotate(-18deg);
        transform: rotate(-18deg);
    }

    14% {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
    }

    16% {
        -webkit-transform: rotate(-12deg);
        transform: rotate(-12deg);
    }

    18% {
        -webkit-transform: rotate(12deg);
        transform: rotate(12deg);
    }

    20% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(-15deg);
        -ms-transform: rotate(-15deg);
        transform: translateZ(0) rotate(-15deg);
    }

    2% {
        -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
        transform: translateZ(0) rotate(15deg);
    }

    4% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: translateZ(0) rotate(-18deg);
    }

    6% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: translateZ(0) rotate(18deg);
    }

    8% {
        -webkit-transform: rotate(-22deg);
        -ms-transform: rotate(-22deg);
        transform: translateZ(0) rotate(-22deg);
    }

    10% {
        -webkit-transform: rotate(22deg);
        -ms-transform: rotate(22deg);
        transform: translateZ(0) rotate(22deg);
    }

    12% {
        -webkit-transform: rotate(-18deg);
        -ms-transform: rotate(-18deg);
        transform: translateZ(0) rotate(-18deg);
    }

    14% {
        -webkit-transform: rotate(18deg);
        -ms-transform: rotate(18deg);
        transform: translateZ(0) rotate(18deg);
    }

    16% {
        -webkit-transform: rotate(-12deg);
        -ms-transform: rotate(-12deg);
        transform: translateZ(0) rotate(-12deg);
    }

    18% {
        -webkit-transform: rotate(12deg);
        -ms-transform: rotate(12deg);
        transform: translateZ(0) rotate(12deg);
    }

    20% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: translateZ(0) rotate(0deg);
    }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-ring {
    -webkit-animation: ring 2s ease infinite;
    animation: ring 2s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
}
