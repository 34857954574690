@import "../variables.scss";
@import "../brand-variables.scss";

.actions-button {
    display: inline-block;
    > button {
        @include border-radius($brand-radius);
        @include remove-select-arrow();
        border: solid 1px $brand-input-border;
        background: rgb(240, 242, 245);
        background: $brand-gradient;
        box-sizing: border-box;
        position: relative;
        color: #7b8292;
        
        padding: 6px 22px 6px 10px;
        margin: 0;
        font-size: 14px;
        min-width: 65px;
        min-height: 31px;
        cursor: pointer;
        background: $brand-arrow-down, $brand-gradient;
        outline: none;
        text-align: left;
        display: inline-block;

        &::-ms-expand {
            display: none;
        }
    
        &:disabled {
            background: $brand-input-disabled-background;
            color: $brand-input-disabled-color;
        }

        &:hover,
        &:focus, &:active {
            border-color: $brand-blue;
        }
    
    }

    .menu {
        font-weight: normal;
        background: $brand-gradient;     

        z-index: 9999;
        visibility: hidden;
        overflow: hidden;
        max-height: 0;
        position: absolute;

        margin-left: 0px;
        margin-top: 2px;
        min-width: 100px;

        padding: 0;
        box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        
        border: solid 1px $brand-input-border;
        @include border-radius(4px);

        > div {
            padding: 16px;
        }
    }


    &.show-menu,
    &:hover,
    &:focus {
        > button {
            border-color: $brand-blue;
        }
    }

    &.show-menu {
        .menu {
            visibility: visible;
            max-height: 900px;
            transition: max-height 400ms ease-in-out;
        }
    }
}
