$brand-white: #fff;
$brand-blue: #0283ff;
$brand-red: #f2594b;
$brand-orange: #f4aa2d;
$brand-green: #8dc754;
$brand-yellow: #ef9400;
$brand-copy-black: #000;
$brand-copy-grey: #707070;
$brand-copy-darkgrey: #3d3d3d;
$brand-light-grey: #ececec;
$brand-header-colour: #3d3d3d;
$brand-body-background: #fff;
$brand-error: #ee0a13;
$brand-icon-grey: #bcbcbc;
$brand-light-blue: #e5f6fa;
$brand-dark-blue: #006b9e;
$brand-background-light-grey: #dee3e9;
$brand-builder-field-background: #f9fafc;
$brand-font-default: "Roboto", sans-serif;
$brand-font-default-weight: 400;
$brand-font-size-default: 14px;
$brand-search-highlight: #d6f2ff;
$brand-admin-button-colour: #bc2c35;
$icon-red: #f03742;
$icon-green: #75ca21;
$icon-grey: #7a8292;
$icon-light-grey: #c4c4c4;
$icon-alt-grey: #575e70;

$brand-input-border: #dde3e8;
$brand-input-color: #3d3d3d;
$brand-input-color-placeholder: #a4a4a4;
$brand-input-disabled-background: #eeeff0;
$brand-input-disabled-color: #707070;
$brand-builder-background-color: #f9fafb;

$brand-table-header-color: #eef0f3;
$brand-table-header-text-color: #1b2125;
$brand-table-row-hover: #f8f8f8;
$brand-modal-copy: #6a7080;
$brand-modal-footer: #eff2fb;
$brand-alt-button-background: #dce3e9;
$brand-table-copy: #7b8292;

$brand-radius: 5px;
/* for select/dropdown menu elements */
$brand-arrow-down: url("../../../img/angle-down-light.svg") right center/21px 21px no-repeat padding-box;
$brand-gradient: linear-gradient(0deg, #f0f2f5 0%, #fefefe 100%);
/* end for select elements */

$brand-checkbox: linear-gradient(0deg, rgb(244, 246, 247) 0%, rgb(250, 251, 251) 100%);
$brand-checkbox-check: url("../../../img/check-light.svg") center center/12px no-repeat content-box;
$brand-checkbox-square: url("../../../img/circle-solid.svg") center center/7px no-repeat content-box;

$brand-public-color: #0283ff;
$brand-internal-color: #006b9e;
$brand-published-color: #8dc754;
$brand-unpublished-color: #9b9b9b;

$border-radius: 4px;

// Internet Explorer 10+ only
@mixin ie-only() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}

.blue {
    color: $brand-blue;
}

.status {
    color: $brand-blue;

    &.pending,
    &.pending-approval,
    &.approval-pending,
    &.approvalpending,
    &.pendingapproval {
        color: $brand-yellow;
    }

    &.black, &.favorites {
        color: #000;
    }

    &.created {
        color: #3d3d3d;
    }

    &.approved, &.complete, &.active {
        color: $brand-green;
    }

    &.inactive{
        color: $brand-red;
    }

    &.rejected, &.error, &.incomplete{
        color: $brand-error;
    }

    &.archived, &.expired {
        color: #c40b0b;
    }

    &.published {
        color: $brand-published-color;
    }
    &.missing-approver {
        color: #ee0a13;
    }
    &.internal,
    &.unpublished {
        color: $brand-unpublished-color;
    }

    &.closed {
        color: grey;
    }

    &.incomplete,
    &.not-started {
        color: #a7a7a7;
    }
}

@mixin brand-font-default() {
    font-family: $brand-font-default;
    font-weight: $brand-font-default-weight;
    font-size: $brand-font-size-default;
}

@mixin font-title() {
    font-family: $brand-font-default;
    font-weight: 300;
    font-size: 40px;
    color: $brand-blue;
}

@mixin font-sub-title() {
    font-family: $brand-font-default;
    font-weight: 300;
    font-size: 24px;
    color: #3d3d3d;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}

@mixin appearance($value) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    appearance: $value;
}

@mixin remove-select-arrow() {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";

    &::-ms-expand {
        display: none;
    }
}

@mixin border-radius-specific($topleft, $topright, $bottomright, $bottomleft) {
    -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
    -moz-border-radius: $topleft $topright $bottomright $bottomleft;
    border-radius: $topleft $topright $bottomright $bottomleft;
}

@mixin box-shadow($horizontal, $vertical, $blurradius, $spreadradius, $color) {
    -webkit-box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
    -moz-box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
    box-shadow: $horizontal $vertical $blurradius $spreadradius $color;
}

@mixin placeholder() {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}

@mixin no-list-style() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin link() {
    color: $brand-white;
    text-decoration: none;
    cursor: pointer;
    font-weight: 300;
}

@mixin noselect() {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

@mixin dropdown-background() {
    background: rgb(242, 244, 247);
    background: -moz-linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f4f7",endColorstr="#ffffff",GradientType=1);
}

@mixin overflow-ellipses() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
