// @import "scaffold-fluid.scss";
@import "lib/variables.scss";

body,
html {
  
  background: #fff;
}

.error {

    .container {

        margin: 50px auto 0;
        overflow: auto;
        padding: 0;

        .copy {

            p {
                line-height: 20px;
                font-size: 12px;

                a,
                a:visited,
                a:hover {
                    color: $pc-blue;
                    text-decoration: none;
                    font-weight: 400;
                }
            }

            h1 {
                font-size: 14px;
                margin-bottom: 8px;
            }

            h2 {
                font-weight: 400;
                font-size: 27px;
                margin-bottom: 16px;
            }

            a.btn {
                display: inline-block;
                margin-top: 10px;
            }
        }

        .logo {
            width: 50px;
            margin: 15px 0;
        }
    }
}

@media (min-width: 700px) {

    .error {

        .container {
            width: 700px;
            height: 280px;
            position: absolute;
            margin: auto;
            overflow: auto;
            padding: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            &.five-hundred {
                height: 195px;
            }
            
            .copy {
                float: left;
                width: 430px;
            }

            .logo {
                width: 200px;
                float: right;
                margin: -5px 0 0 0;
            }
        }
    }
}
