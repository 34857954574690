﻿@import "../brand-variables.scss";
@import "../variables.scss";
@import "../upload.scss";
@import "./actions-button.scss";

.editor-with-icon {
    @include border-radius($brand-radius);
    border: solid 2px #dde3e8;
    padding-left: 30px;
    @include appearance(textfield);
    background-color: #fff;
    min-width: 240px;
    position: relative;

    i {
        color: #a6a6a6;
        position: absolute;
        left: 10px;
        top: 8px;
    }

    > input[type] {
        font-size: 12px;
        display: inline-block;
        border: none;
        outline: none;
        padding: 8px 10px 8px 0;
    }

    &:hover,
    &:focus-within {
        border-color: $brand-blue;
    }
}

@mixin brand-clickable-field() {
    @include border-radius($brand-radius);
    @include remove-select-arrow();
    border: solid 1px $brand-input-border;
    background: rgb(240, 242, 245);
    background: $brand-gradient;
    box-sizing: border-box;
    position: relative;
    color: #7b8292;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
}

label[for] {
    display: block;
    padding: 2px;
}

select,
.select {
    @include brand-clickable-field();
    padding: 6px 22px 6px 10px;
    margin: 0;
    font-size: 14px;
    min-width: 65px;
    min-height: 31px;
    cursor: pointer;
    background: $brand-arrow-down, $brand-gradient;
    outline: none;
    text-align: left;
    display: inline-block;

    &.show-menu,
    &:hover,
    &:focus {
        &:not(:disabled) {
            border-color: $brand-blue;
        }
    }

    &::-ms-expand {
        display: none;
    }

    &:disabled {
        background: $brand-input-disabled-background;
        color: $brand-input-disabled-color;
    }

    &.invalid {
        border: solid 1px $brand-error;

        @include placeholder {
            color: $brand-header-colour;
            font-weight: 300;
        }
    }

    > span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
    }

    > div.menu {
        z-index: 9999;
        display: none;
        @include brand-clickable-field();
        position: absolute;
        padding: 5px;

        margin-left: -11px;
        margin-top: 7px;
        cursor: default;
        input {
            display: block;
            margin-bottom: 4px;
        }

        ul {
            max-height: 300px;
            min-width: 150px;
            overflow-y: auto;
            padding: 0;
            margin: 0;

            li {
                cursor: pointer;
                padding: 5px 5px;
                margin: 0;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover,
                &.selected {
                    background-color: $brand-light-blue;
                }

                &.no-result {
                    cursor: default;
                    background-color: transparent;
                    font-size: 0.9em;
                    color: $brand-copy-grey;
                }
            }
        }
    }

    &.show-menu {
        > div.menu {
            display: block;
        }
    }
}

.switch > input[type="checkbox"] {
    display: none;
}

.virtual-checkbox,
input[type="checkbox"] {
    @include appearance(none);
    @include border-radius(3px);
    border: solid 1px $brand-input-border;
    @include box-shadow(0px, 1px, 2px, 0px, #b7babe8c);
    width: 18px;
    height: 18px;
    outline: none;
    text-align: center;
    cursor: pointer;
    background: $brand-checkbox;
    color: #5d6375;
    padding: 1px 0 0 0;
    margin: 0 4px 0 0;

    &:hover {
        border-color: $brand-blue;
    }

    &.checked,
    &:checked {
        background: $brand-checkbox-check, $brand-checkbox;
    }

    &.disabled {
        opacity: 0.7;
    }

    &[readonly] {
        cursor: default;

        &:hover {
            border-color: $brand-input-border;
        }
    }

    &[indeterminate],
    &[indeterminate="true"] {
        background: $brand-checkbox-square, $brand-checkbox;
    }
}

.virtual-checkbox {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px 0 0;
}

.textarea,
input:not([type="checkbox"]),
textarea {
    @include brand-font-default;
    width: 100%;
    padding: 6px 10px;
    border: solid 1px $brand-input-border;
    font-size: 14px;
    @include border-radius(4px);
    color: $brand-input-color;
    box-sizing: border-box;
    outline: none;

    @include placeholder {
        color: $brand-input-color-placeholder;
        font-weight: 300;
    }

    &:disabled {
        background: $brand-input-disabled-background;
        color: $brand-input-disabled-color;
    }

    &:focus,
    &:hover,
    &:active {
        border-color: $brand-blue;
    }
}

input[type="radio"] {
    width: 30px;
}

textarea {
    max-width: 100%;
    min-width: 100%;
    height: 150px;
}

input.invalid,
textarea.invalid,
input:not([type="checkbox"]).invalid {
    border: solid 1px $brand-error;

    @include placeholder {
        color: $brand-header-colour;
        font-weight: 300;
    }
}

.dropdown-menu {
    position: absolute;
    background-color: #fff;
    color: $pc-blue;
    list-style-type: none;
    @include border-radius(4px);
    z-index: 21;
    padding: 10px;
    width: 252px;
    border: solid 2px #dcdee6;
    @include dropdown-background();

    &.show-menu {
        display: inline-block;
    }

    &.hide-menu {
        display: none;
    }

    li {
        color: #000;
        padding: 5px 10px;
        line-height: 18px;

        label {
            color: inherit;
        }

        i {
            color: #3d3d3d;
            margin-right: 15px;

            &.star {
                color: #daa520;
                margin-left: 5px;
                font-size: 12px;
                vertical-align: middle;
                padding-bottom: 3px;
            }
        }
    }

    li:not(.disabled):hover {
        color: $pc-blue;
        cursor: pointer;

        i {
            color: $pc-blue;
        }
    }
}

select:disabled,
button:disabled {
    cursor: default;
}

button {
    vertical-align: middle;
    min-height: 33px;
    > span {
        vertical-align: middle;
    }
    .hourglass-wrapper,
    .loader {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        height: 17px;
    }

    .loader {
        box-sizing: border-box;
        width: 17px;
        height: 17px;
        margin-left: 3px;
        filter: brightness(0) invert(1);
    }

    .saved {
        display: inline-block;
        vertical-align: middle;
        width: 17px;
        height: 17px;
        margin-left: 3px;
        filter: brightness(0) invert(1);
    }
}
