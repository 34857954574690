﻿@import "brand-variables.scss";
@import "filter-header.scss";

.district-select {
    @include noselect;
    display: inline-block;
    font-size: 12px;

    .select {
        position: relative;
        width: 250px;

        span {
            position: absolute;
            width: 210px;
            font-size: 12px;
            display: inline-block;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 10px;
            height: 14px;
            @include overflow-ellipses;
        }
    }

    header {
        border-bottom: solid 1px $brand-input-border;
        padding-bottom: 20px;
        text-align: left;

        > span {
            color: #7b8292;
            font-size: 18px;
            display: inline-block;
            font-weight: 500;

            &:first-of-type {
                padding-top: 3px;
            }
        }

        .switch-container {
            display: inline-block;
            margin: 0 20px;

            label,
            .switch {
                vertical-align: middle;
            }
        }

        .search-container {
            border: solid 1px $brand-input-border;
            float: right;
            line-height: initial;
            font-size: 12px;
            width: 250px;

            > input {
                font-size: 12px;
                padding: 5px 25px 5px 15px;
            }

            .clear-search {
                right: 10px;
                top: 4px;
            }
        }

        .close {
            position: absolute;
            right: 5px;
            top: 5px;
            font-size: 15px;
            cursor: pointer;
        }
    }

    .district-list {
        position: absolute;
        border: solid 1px $brand-input-border;
        @include border-radius(5px);
        padding: 20px;
        margin: 5px 0 0;
        width: 610px;
        font-size: 12px;
        color: #7c8c98;
        background: $brand-gradient;
        z-index: 1;

        .districts {
            max-height: 500px;
            overflow: auto;
            padding: 0 5px;

            ul {
                clear: both;
                @include border-radius(4px);

                &:hover,
                &.selected {
                    background: #7c8c98;

                    li {
                        color: $brand-white;
                    }
                }

                &.selected {
                    background: $brand-blue;
                }

                &.disabled {
                    opacity: 0.5;
                }
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 5px -5px;
            padding: 0 5px;
            max-height: 200px;
            cursor: pointer;

            li {
                display: inline-block;
                vertical-align: middle;
                width: 75px;
                padding: 5px 10px;
                text-align: left;

                &.district-name {
                    padding-left: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                &.edition {
                    width: 110px;
                    position: relative;

                    .header-filters {
                        .filters {
                            height: 264px;

                            .list {
                                height: 222px;
                            }
                        }
                    }
                }

                &.district,
                &.district-name {
                    font-weight: 300;
                    padding-left: 0;
                    width: 252px;
                }
            }

            &.list-header {
                border-bottom: solid 1px $brand-input-border;
                margin-bottom: 10px;
                cursor: default;
                margin: 5px 0;

                li {
                    padding: 10px;
                    font-weight: 500;

                    > div {

                        color: $brand-copy-grey;
                    }

                    &.edition {
                        color: #7c8c98;
                    }

                    &.district-name {
                        font-weight: 500;
                        padding-left: 0;
                    }

                    &.district {
                        padding-left: 0;
                    }
                }
            }
        }

        .header-filters {
            span,
            i {
                color: #7c8c98;
                font-weight: 500;
            }
            .filters {
                p {
                    color: #7c8c98;
                }
            }
        }
    }
}
