@import "variables.scss";
@import "brand-variables.scss";

#preview-header,
#event-editor-header {
    margin-bottom: 10px;
    padding-top: 0px;

    .header-document-name {
        font-size: 16px;
        margin-bottom: 15px;
    }

    .recipient-message {
        background-color: $brand-yellow;
        display: flex;
        justify-content: center;
        height: 40px;

        p {
            margin: auto 5px auto 0;
            font-weight: normal;
        }

        & > .btn {
            height: 18px;
            padding: 5px;
            background-color: $utility-navy;
            margin: auto 0;
            font-size: 14px;
        }

        footer > p {
            margin-top: 15px;
        }

        .scheduled-message {
            margin-left: 15px;
            display: inline-flex;

            i {
                margin-right: 5px;
            }

            .btn {
                height: 18px;
                padding: 5px;
                margin: auto 0;
                font-size: 14px;
                background-color: red;
            }
        }
    }

    h1,
    h3 {
        font-size: 24px;
        margin-bottom: 0;
    }

    .create-preview-details {
        margin-top: 10px;

        .form-created-by {
            display: inline-block;
            width: 50%;

            i {
                color: $brand-blue;
                font-size: 18px;
                padding-right: 10px;
            }

            p {
                font-size: 12px;
                margin: 0 0 5px;
            }

            .process {
                a,
                a:hover,
                a:visited {
                    text-decoration: none;
                    font-weight: 400;
                    color: $brand-copy-black;
                    font-size: 14px;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        button {
            float: right;
            margin-left: 10px;
            background: $brand-dark-blue;
            padding: 8px 20px;
            min-width: 120px;

            &.form-invalid {
                background-color: $brand-error;
                cursor: default;
            }
        }
    }

    .process-detail-info {
        overflow: auto;

        .created-by {
            float: left;
            width: 45%;

            p {
                margin: 15px 0 0;

                &:last-of-type {
                    margin-top: 5px;
                }
            }

            i.process {
                vertical-align: middle;
            }

            a,
            a:visited,
            a:hover {
                color: $brand-blue;
            }
        }

        .template-info {
            float: right;
            width: auto;
            max-width: 45%;
            text-align: right;
            font-size: 16px;
            color: $brand-copy-black;

            p {
                margin-top: 15px;

                &:first-of-type {
                    margin-bottom: 5px;
                }

                &.descriptor {
                    margin: 0;
                }
            }

            i {
                padding-right: 5px;
                color: $brand-blue;
            }
        }
    }

    #preview-menu {
        cursor: pointer;
        float: right;
        background: $brand-blue;
        color: $brand-white;
        padding: 10px 20px;
        font-size: 16px;
        @include border-radius(4px);
        @include noselect;
        position: relative;
        outline: none;

        i {
            color: $brand-white;
            vertical-align: top;
            margin-left: 10px;
        }

        &.hide-menu ul {
            display: none;
        }

        &.show-menu ul {
            display: block;
            position: absolute;
            top: 24px;
            right: 0;
            list-style-type: none;
            padding-left: 0;
            width: 160px;
            z-index: 1;
            background: rgb(242, 244, 247);
            background: -moz-linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
            background: -webkit-linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
            background: linear-gradient(0deg, rgba(242, 244, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f4f7",endColorstr="#ffffff",GradientType=1);
            border: 1px solid #ced0da;
            padding: 0 4px;
            @include border-radius(4px);

            li {
                border-bottom: 1px solid #ced0da;
                padding: 15px 0;
                text-align: center;
                font-size: 16px;
                color: #7b8292;

                &:last-of-type {
                    border-bottom: none;
                }

                i {
                    margin: 0 8px;
                }

                a {
                    color: #7b8292;
                    display: inline-block;
                    text-decoration: none;
                }
            }
        }
    }

    &.process-template,
    &.process,
    &.single-row {
        h1,
        h3 {
            #preview-menu {
                margin-top: 0;
            }
        }
    }
}

.document-validation-icon {
    position: relative;
    text-align: right;

    i {
        font-size: 22px;
        color: #ddb527;
    }
}

.document-validation-list {
    position: absolute;
    z-index: 50;
    background: $brand-white;
    padding: 15px;
    font-size: 12px;
    border: solid 1px $brand-input-border;
    text-align: left;
    width: 350px;
    right: 0;
    top: 35px;
    @include border-radius(4px);
    -webkit-box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.15);
    -moz-box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.15);
    box-shadow:
        0 3px 6px rgba(0, 0, 0, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.15);

    header {
        font-weight: 500;
    }

    ol {
        padding: 0 0 0 15px;

        li {
            margin-bottom: 10px;
        }
    }
}

.create-preview-details {
    .create-details {
        display: flex;
        justify-content: space-between;

        .form-created-by {
            min-width: 30%;
        }
    }

    margin-top: 0;
    margin-bottom: 30px;
    p {
        font-size: 12px;
        margin: 5px 0;
    }
}

.approval-page .single-row .layout-content button {
    float: right;
}

#horizontal-rule {
    background: $brand-input-border;
    width: 100%;
    height: 1px;
    overflow: hidden;
}

#event-editor-header > ul {
    list-style-type: none;
    height: 40px;
    display: flex;
}

#event-editor-header > ul > li {
    display: inline-block;
    line-height: 40px;
    margin-right: 16px;
}

#event-editor-header > ul > li.show-menu {
    background-color: #9b9b9b;
    border-radius: 32px;
}

#event-editor-header > ul > li a {
    display: block;
    text-decoration: none;
}

#event-editor-header > ul > #publish-or-send-for-approval,
#event-editor-header > ul > .action-button {
    background-color: #fff;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.1);
    color: $brand-blue;
    border-radius: 8px;
    padding: 0 10px;
    font-weight: 400;
    cursor: pointer;
    font-size: 16px;
    float: right;
}

#event-editor-header > ul > li.exit {
    cursor: pointer;
}

#event-editor-header > ul > #publish-or-send-for-approval.form-invalid,
#event-editor-header > ul > .action-button.cancel {
    background-color: $brand-error;
    color: #fff;
    cursor: default;
}

#event-editor-header > .action-button.cancel {
    padding: 0 30px;
}

#event-editor-header .district-deployed,
#event-editor-header .awaiting-approval,
#event-editor-header .archived,
#event-editor-header .rejected,
#event-editor-header .inactive,
#event-editor-header .approved,
#event-editor-header .complete,
#event-editor-header .notstarted,
#event-editor-header .inprogress,
#event-editor-header .active,
#event-editor-header .draft,
#event-editor-header .published,
#event-editor-header .closed {
    background-color: $brand-yellow;
    color: $brand-white;
    cursor: default !important;
    font-size: 11px;
    @include border-radius(4px);
    padding: 5px 10px;
    margin: 0 0 0 15px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    outline: none;

    &.response {
        :root > :not(.approval-page) {
            margin: 0;
        }
    }
}

#event-editor-header {
    .rejected,
    .inactive {
        background-color: $brand-error;
    }
}

#event-editor-header .draft,
#event-editor-header .inprogress {
    background-color: $brand-blue;
}

#event-editor-header {
    .approved,
    .active,
    .complete {
        background-color: $brand-green;
    }
}

#event-editor-header {
    .notstarted {
        background-color: #6a7080;
    }
}

#event-editor-header .published,
#event-editor-header .district-deployed {
    background-color: $brand-green;
    margin-left: 30px;
    display: inline-block;
}

#event-editor-header .archived {
    background-color: #c40b0b;
}

#event-editor-header .closed {
    background-color: $brand-icon-grey;
}

#event-editor-header .disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    border-color: $brand-input-border;
}

#event-editor-header > ul > li > i.locked {
    color: #4a4a4a;
}

@media (min-width: 768px) {
    #event-editor-header.fixed-header,
    #preview-header.fixed-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        background: $brand-white;
        border-bottom: solid 1px $brand-input-border;
        margin-bottom: 20px;

        h3,
        h1 {
            margin-top: 0;
            margin-bottom: 0;

            .layout-content {
                padding: 0;
                border: none;
            }
        }

        .layout-content {
            padding: 0;
            border: none;
        }
    }

    .header-placeholder {
        height: 120px;
        width: 100%;

        &.unsent-responses {
            height: 160px;
        }
    }
}

#event-editor-header div.confirmation,
#event-editor-header div.publish-errors,
#event-editor-header div.animation-modal {
    position: absolute;
    color: #000;
}
