﻿@import "brand-variables.scss";

table {
    width: 100%;
}

table.dataTable {
    clear: both;
    border-collapse: collapse;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
    border-bottom: solid 1px #dedede;
    padding-left: 2px;
    color: #989898;
}

table.dataTable tbody th,
table.dataTable tbody td {

    padding: 4px 6px 4px 4px;
}

table.dataTable.no-footer {
    border-bottom: none;
}

table.for-print,
table.for-print thead th,
table.for-print thead td,
table.for-print tfoot th,
table.for-print tfoot td {
    border: none;
    border-bottom: solid 1px black;
}

table.dataTable tbody td {
    font-size: 14px;
    color: #4a4a4a;
    border-bottom: solid 1px #dedede;
    padding: 10px;
    font-weight: 300;
}

table.dataTable tbody td.reminder {
    text-decoration: underline;
    color: $brand-blue;
}

table.dataTable tbody td.reminder:hover {
    color: $brand-blue;
}

table.dataTable tbody td .btn {
    font-size: 13px;
}

table.dataTable tbody th i,
table.dataTable tbody td i {
    font-size: 14px;
    display: none;
}


table.dataTable tbody th i.isProcess,
table.dataTable tbody td i.isProcess {
    display: inline-block;
    font-size: 20px;
    vertical-align: inherit;
    margin-right: 5px;
}

table.dataTable tbody td i.reminder {
    float: left;
    margin-right: 3px;
    position: relative;
    bottom: -2px;
    font-size: 13px;
    display: inline-block;
    color: $brand-blue;
}

table.dataTable tbody tr.show-icon td i,
table.dataTable tbody tr:hover td i {
    display: block;
}

table.dataTable tbody tr:hover td i.isProcess {
    display: inline-block;
}

table.dataTable tbody tr:hover i.reminder,
table.dataTable tbody td i.reminder:hover {
    display: inline-block;
}

table.clickable-rows tbody tr:hover td.reminder {
    color: $brand-blue;
}

table.dataTable tbody tr.show-icon td .show i,
table.dataTable tbody tr:hover td .show i {
    display: none;
}

table.dataTable tbody tr td i.delete-template {
    visibility: hidden;
    display: block;
}

table.dataTable tbody tr:hover td i.delete-template {
    visibility: visible;
}

table.dataTable tbody tr td i:hover {
    color: $brand-blue;
}

table.dataTable tbody tr td i.isProcess:hover {
    color: #4a4a4a;
}

table.dataTable tbody tr td .hourglass {
    margin-top: 0;
    width: 10px;
    max-height: 18px;
}

table.dataTable tbody tr td div.star.favorite {
    color: $brand-blue;
}

table.dataTable tbody tr td div.star.favorite i {
    display: block;
}


table.dataTable tbody td .hourglass-container {
    width: 20px;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: solid 1px #dedede;
}

.dataTables_wrapper.no-footer label {
    background-color: #fff;
    padding-top: 5px;
    padding-bottom: 6px;
}

.dataTables_filter {
    padding-right: 6px;
}

div.DTTT_container {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0.5em;
    float: left;
}

a.DTTT_button {
    background: $brand-blue;
    color: #fff !important;
    margin-right: 4px;
    margin-bottom: 2px;
    border-radius: 5px;
    display: inline-block;
    padding: 6px 12px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    font-size: 17px;
}

a.DTTT_button:hover {
    background: #0091b5;
    box-shadow: none;
    outline: 0;
    border: 1px solid transparent;
}

#Responses a.DTTT_button {
    font-size: 13px;
    padding: 3px 12px;
}

table.dataTable thead th,
table.dataTable thead td,
table.dataTable tfoot th,
table.dataTable tfoot td {
    text-align: left;
    color: #4a4a4a;
    font-size: 14px;
}

.dataTables_wrapper .dataTables_filter {
    margin-bottom: 20px;
    float: left;
}

.dataTables_wrapper .dataTables_filter input {
    border: $brand-input-border;
    @include border-radius(8px);
    padding-left: 40px;
    background: url('../../img/search-gray.png') no-repeat 10px 10px;
    -webkit-appearance: textfield;
    margin-left: 0;
}

.dataTables_wrapper .dataTables_paginate {
    float: none;
    margin-top: 1.25em;
    margin-right: auto;
    margin-left: auto;
    min-width: 346px;
    max-width: 700px;
    text-align: center;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button {
    background: $brand-blue;
    color: $brand-white !important;
    border: none;
    outline: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    cursor: pointer;
}

.dataTables_length select {
    width: 70px;
    margin-right: 10px;
}

table.clickable-rows tbody tr:hover td {
    cursor: pointer;
    background: $brand-table-row-hover;
    color: #5f5f5f;
}

table.clickable-rows tbody tr:hover td:first-child {
    -moz-border-radius: 2px 0 0 2px;
    -webkit-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
}

table.clickable-rows tbody tr:hover td:last-child {
    -moz-border-radius: 0 2px 2px 0;
    -webkit-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
}

table.clickable-rows tbody tr:hover td:last-child:first-child {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

table.clickable-rows tbody tr:hover td.dataTables_empty {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

table.clickable-rows tbody tr.no-data td {
    cursor: default;
    background: #fff;
    color: #333;
}

table.clickable-rows tbody tr:hover td.dataTables_empty {
    background: #fff;
    color: #000;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    cursor: default;
}

table > tbody > tr > td > div.no-schools {
    margin: 20px auto;
    font-size: 16px;
}

