﻿@import "brand-variables.scss";
@import "../../../../node_modules/@uppy/core/dist/style";
@import "../../../../node_modules/@uppy/drag-drop/dist/style";
@import "../../../../node_modules/@uppy/progress-bar/dist/style";

.upload-wrapper {
    &.disabled {
        opacity: 0.6;
    }
}

button.uppy-Root,
.upload-disabled.uppy-Root,
button.uppy-u-reset {
    border: 1px dashed $brand-input-border;
    background: url("../../img/upload-background.png") no-repeat center 30px;
    @include border-radius(3px);

    &:focus {
        outline: none;
        box-shadow: none;
    }

    .uppy-DragDrop-arrow {
        display: none;
    }

    .uppy-DragDrop-label {
        padding: 100px 0 0;
        font-size: 12px;

        .uppy-DragDrop-browse {
            background: transparent;
            border: solid 1px $brand-input-border;
            color: $brand-copy-grey;
            padding: 6px 10px;
            text-transform: capitalize;
            @include border-radius(3px);
            display: block;
            margin: 10px 0 0;
        }
    }

    .uppy-DragDrop-inner {
        padding: 40px 20px;
    }
}

.upload-disabled.uppy-Root {
    opacity: 0.6;
    width: 100%;
    height: 86%;
    @include border-radius(3px);
    cursor: default;

    span {
        text-align: center;
    }
    .uppy-DragDrop-label {
        .uppy-DragDrop-browse {
            cursor: default;
        }
    }
}
