﻿.main > #content {
    min-height: calc(100vh - 70px - 80px);
}

.main > #site-header > header {
    height: 80px;
}

.main > #private-footer {
    height: 70px;
}

@media (max-height: 400px) {
    .main>#content {
        min-height: 300px;
    }
}


.layout-content {
    max-width: 960px;
    margin: 0;
    padding: 0 20px;
}

@media (min-width: 768px) {

    .layout-content {
        padding: 0;
        margin: 0 auto;
    }
}

@media (min-width: 1200px) {
    .school-dashboard-content .table-container table,
    .layout-content {
        max-width: 1170px;
    }

    /* Fix for fluid layout in Internet Explorer 10 & 11*/
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .layout-content {
            margin-left: auto;
            margin-right: auto;
            max-width: 1170px;
        }
    }
}

@media (min-width: 1400px) {
    .school-dashboard-content .table-container table,
    .layout-content {
        max-width: 1260px;
    }
}