

.unseen-report-requests{

    display: inline-block;
    vertical-align: super;
    font-size: smaller;
    border-radius: 50px;
    height: 16px;
    width: 13px;
    padding-left: 5px;
    padding-top: 3px;
    cursor: default;

    &.one-digit, &.two-digit, &.three-digit{
        color: $brand-white;
        background-color: $brand-red;
    }

    &.two-digit{
        padding-left: 4px;
        width: 18px;
    }

    &.three-digit{
        padding-left: 4px;
        width: 26px;
    }
}

.organization-header > ul > li > span > .unseen-report-requests{
    margin-left: -5px;
}

.tab-strip .unseen-report-requests{
    position: absolute;
    top: -10px;
    left: 75px;
}