﻿@import "brand-variables.scss";

.modal-background,
.animation-modal-background {
    z-index: 999;
    background: #7d7d7d;
    position: fixed;
    opacity: 0.8;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-content-container.delete-responses {
    height: fit-content;
    width: 450px;

    @include ie-only() {
        height: 400px;
    }

    .delete-responses-container {
        footer {
            display: block;
            position: relative;
            padding: 20px 0 0 0;
        }
        .content {
            color: #a0acb5;
            font-weight: 500;

            input {
                margin-right: 10px;
            }

            div {
                margin-bottom: 15px;
                cursor: pointer;
            }

            .partially-complete {
                span {
                    color: #fac058;
                }
            }
            .unsent {
                span {
                    color: #e36a65;
                }
            }
            .rejected {
                span {
                    color: #e36a15;
                }
            }
            .approved {
                span {
                    color: #21a83f;
                }
            }
            .pending-approval {
                span {
                    color: #2b1fff;
                }
            }
            .complete {
                span {
                    color: #2ba9e9;
                }
            }
            .pending {
                span {
                    color: #fac024;
                }
            }
            .opened {
                span {
                    color: #21f9e9;
                }
            }
            .unopened {
                span {
                    color: #21f165;
                }
            }

            p.last-warning {
                color: #a0acb5;
                font-weight: 600;
                margin-top: 15px;
            }
        }
    }
}

.modal-content-container.larger {
    height: 300px;
    width: 600px;
}

.modal-content-container.small {
    height: 170px;
    width: 400px;
}

.modal-content-container.largest {
    @media (min-width: 481px) {
        height: 450px;
        width: 600px;
    }

    @media (max-width: 480px) {
        max-width: 480px;
        width: 80%;
        height: 600px;
        position: relative;
    }
}

.modal-content-container.fullscreen {
    height: 90vh;
    width: 90vw;
}

@media (max-width: 480px) {
    .modal-content-container.fullscreen {
        height: 100vh;
        width: 100vw;
    }
}

.download-pdf > .modal-content-container {
    height: 285px;
    width: 800px;
    max-width: 800px;
    padding-left: 25px;

    > header {
        color: $brand-header-colour;
        background-color: #fff;
        font-size: 30px;
        padding-bottom: 5px;
        padding-left: 5px;
    }

    > .modal-headline {
        color: #979797;
        font-size: 14px;
        text-align: left;
        padding: 5px 5px 15px 5px;
        font-weight: 500;
        margin-top: 10px;
    }

    > .options {
        display: flex;

        > .condensed,
        .expanded {
            width: 50%;

            > .modal-button {
                background-color: $brand-blue;
                font-size: 14px;
            }

            > .modal-content {
                color: #414141;
                padding-left: 2px;
                padding-top: 20px;
                font-size: 14px;
                width: 85%;

                > .printer-friendly {
                    font-weight: bold;
                }
            }
        }
    }
}

.animation-modal-content,
.modal-content-container {
    z-index: 1000;
    position: fixed;
    @include border-radius(5px);
    background: $brand-white;
    overflow: auto;
    margin: auto;
    top: 0;
    left: 0;
    padding: 20px 40px;
    height: 250px;
    width: 400px;

    @media (min-width: 481px) {
        bottom: 0;
        right: 0;
    }

    header,
    h1 {
        color: $brand-copy-darkgrey;
        margin: 0 0 15px;
        background: none;
        font-size: 26px;
    }

    .descriptor {
        font-size: 14px;
        color: $brand-copy-grey;
        margin: 10px 0;
        font-weight: 300;

        &:first-of-type {
            margin-bottom: 0;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 20px;
        color: $brand-background-light-grey;
        display: block;
        cursor: pointer;
    }

    .modal-no {
        background: $brand-error;
        margin-right: 15px;
    }

    .modal-secondary-option {
        background: transparent;
        border: solid 1px $brand-copy-grey;
        color: $brand-copy-grey;
        margin-right: 15px;
    }

    .modal-question,
    .modal-body {
        font-size: 14px;
    }

    label.refund-confirm {
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 0;

        i {
            font-size: 14px;
            color: $brand-blue;
        }

        .confirm-check {
            width: 4%;
        }

        .confirm-label {
            width: 96%;
        }

        div {
            display: inline-block;
            font-weight: bold;
            vertical-align: top;
        }
    }

    .search {
        border: solid 1px $brand-input-border;
        padding: 6px 10px;
        @include border-radius(3px);
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;
        float: right;

        i {
            color: #a6a6a6;
            vertical-align: middle;
            font-size: 18px;
            margin: 0;
            position: absolute;
            top: 5px;
            left: 10px;
        }

        input {
            border: none;
            padding: 0;
            width: 100%;
            outline: none;
        }
    }

    .search-old-table-input-margins {
        margin-top: 4px;
        margin-left: 10px;
    }

    .tab-strip {
        .tab {
            span {
                display: none;
            }
        }
    }

    .tab-page {
        padding: 20px 0;
    }

    img {
        width: 110px;
        display: block;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &.loader {
            width: auto;
            max-width: 130px;
            max-height: 130px;
        }
    }

    .loader {
        margin: auto;
        width: 150px;
        height: 150px;
        background: url("../../img/tail.svg") no-repeat;
        background-size: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            background-image: url("../../img/tail-ns.gif");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
        }

        @supports (-ms-accelerator: true) {
            /* IE Edge 12+ CSS */
            background-image: url("../../img/tail-ns.gif");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
        }

        @supports (-ms-ime-align: auto) {
            /* IE Edge 16+ CSS */
            background-image: url("../../img/tail-ns.gif");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
        }
    }

    button:disabled:not(.modal-footer-button) {
        cursor: default;
    }

    .confirmbox-question {
        font-size: 16px;
        margin-bottom: 20px;
    }

    footer {
        padding: 40px;
        text-align: center;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        box-sizing: border-box;

        &.alternate {
            background-color: $brand-modal-footer;
            padding: 16px 25px;
            text-align: right;
            @include border-radius-specific(0, 0, 7px, 7px);
        }

        button:last-of-type {
            margin-right: 0;
        }

        .loader {
            width: 75px;
            height: 75px;
        }

        button {
            margin-right: 15px;

            @media (max-width: 480px) {
                margin: 5px;
            }
            .hourglass-wrapper {
                display: inline;
                position: inherit;
                vertical-align: middle;
                .loader {
                    position: inherit;
                    display: inline-block;
                    vertical-align: middle;
                    width: 20px;
                    height: 20px;
                    margin: 0;
                    margin-left: 6px;
                    top: auto;
                    left: auto;
                    right: auto;
                    bottom: auto;
                }
            }
        }
    }
}

.deprecated .modal-content-container {
    width: 750px;
    height: 550px;
}

.animation-modal-content {
    width: 220px;
    border: none;

    p {
        text-align: center;
        color: $brand-blue;
        border: none;
        font-weight: 600;
        margin: 0;
    }
}
