@import "brand-variables";
@import "modal-light.scss";
@import "tables.scss";
@import "tabs.scss";
@import "common/form-elements.scss";

html,
body {
    color: $brand-copy-black;
    @include brand-font-default;
    background: $brand-body-background;
    margin: 0;
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    color: $brand-header-colour;
    font-weight: 400;
}

h1 {
    font-size: 24px;

    .sub-header {
        margin: 10px 0;
        font-size: 16px;
        color: $brand-copy-grey;
        max-width: 600px;
    }
}

h2 {
    font-size: 24px;
    margin-bottom: 18px;
}

h3 {
    font-size: 24px;
    margin-bottom: 16px;
}

h4 {
    font-size: 22px;
    margin-bottom: 10px;
}

h5 {
    font-size: 18px;
    margin-bottom: 2px;
}

h6 {
    font-size: 16px;
    margin-bottom: 2px;
}

.btn,
button,
input[type="submit"] {
    font-family: $brand-font-default;
    font-size: 15px;
    font-weight: 400;
    background: $brand-blue;
    color: $brand-white;
    padding: 10px 18px;
    border: none;
    @include border-radius(7px);
    cursor: pointer;
    text-decoration: none;
    @include noselect;

    .hourglass-wrapper {
        img {
            height: 15px;
            width: 15px;
        }
    }

    .hourglass-wrapper {
        color: $brand-white;
    }

    &.secondary-option {
        background: transparent;
        border: solid 1px $brand-copy-grey;
        color: $brand-copy-grey;
        margin-right: 15px;
    }
}

button:disabled:not(.modal-footer-button),
input[type="submit"]:disabled {
    opacity: 0.4;
    cursor: default;

    &:not(.btn-toggle) {
        background: $brand-copy-grey;
        color: $brand-white;
    }

    &.btn-toggle {
        border-color: $brand-copy-grey !important;
        color: $brand-copy-grey;

        &:not(.selected) {
            background: $brand-light-grey !important;
            color: $brand-copy-grey;
        }

        &.selected {
            background: $brand-copy-grey;
        }
    }
}

.btn.cancel,
button.cancel {
    background: $brand-error;
}

.btn.disabled {
    background: #f6f6f6;
    color: $brand-copy-grey;
}

.btn.plain,
.btn-plain,
button.plain,
input[type="submit"].plain {
    background: none;
    padding: 0;
    color: $brand-blue;

    i {
        font-size: 24px;
        vertical-align: middle;
        margin: -3px 5px 0;
    }
}

.error-message {
    color: $brand-error;
    font-size: 12px;
    font-style: italic;
}

label {
    color: $brand-header-colour;
    font-size: 14px;
    display: inline-block;
}
.hint-icon {
    cursor: help;
}
table {
    width: 100%;
    border-spacing: 0;

    thead {
        border-bottom: 1px solid $brand-input-border;

        tr {
            th {
                font-size: 14px;
                color: $brand-table-header-text-color;
                font-weight: 400;
                padding-bottom: 15px;
                @include noselect;
            }
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $brand-input-border;
        }
    }
}

i.lock,
i.add {
    font-size: 20px;
    cursor: pointer;

    &.add {
        color: $brand-blue;
    }

    &.lock {
        color: $brand-icon-grey;
    }
}

div.switch-container {
    outline: 0;

    &:focus,
    &:active,
    &:hover {
        label:not(.disabled) {
            text-decoration: underline;
            color: $brand-blue;
        }
    }

    label.switch-label {
        display: inline-block;
        vertical-align: middle;

        &:not(.after) {
            padding-right: 10px;
        }

        &.after {
            padding-left: 10px;
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        width: 35px;

        i.fa-toggle-on {
            font-size: 28px;
            transition: color 0.2s ease-in-out;
            color: $brand-icon-grey;

            &.checked {
                color: $brand-blue;
            }
        }
    }

    .switch:not(.disabled),
    .switch-label:not(.disabled) {
        cursor: pointer;
    }

    .switch.disabled {
        opacity: 0.5;
    }
}

.dropdown-button {
    button.selected {
        background: $brand-green;
    }

    &.show-menu ul {
        display: block;
    }

    button {
        margin: 0;
        text-align: left;
        overflow: hidden;

        &:focus {
            outline: 0;
        }
    }

    ul {
        display: none;
        position: absolute;
        font-size: 14px;
        margin: 26px 0 0 0;
        padding: 0;
        list-style: none;
        background: #fff;
        z-index: 500;
        min-width: 100%;
        border: solid 1px #e6e7e8;
        border-top: none;
        box-sizing: border-box;
        white-space: nowrap;

        @include border-radius-specific(0, 0, 4px, 4px);
        @include box-shadow(2px, 2px, 4px, 0, rgba(0, 0, 0, 0.3));

        li {
            border-bottom: solid 1px #e6e7e8;
            &.selected {
                background: $brand-light-blue;
            }

            &:last-child {
                border: none;
            }

            a,
            a:visited,
            a:hover {
                padding: 10px;
                text-decoration: none;
                color: $brand-blue;
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
            }

            &:hover {
                a,
                a:visited,
                a:hover {
                    background: $brand-table-row-hover;
                }
            }
        }
    }
}

div.bool-toggle[disabled="disabled"] {
    opacity: 0.6;
}

div.bool-toggle > label {
    display: block;
    padding-bottom: 3px;
}

.btn-toggle,
button.btn-toggle,
.form-builder-btn-toggle {
    background: #f6f6f6;
    color: $brand-blue;
    border: solid 2px $brand-blue;
    padding: 7px 15px;
    outline: none;
    font-size: 12px;
    float: none !important;
    @include border-radius(7px);
    cursor: pointer;

    &:focus {
        color: $brand-blue;
    }

    &:disabled {
        background: #f6f6f6;
        color: $brand-copy-grey;
        cursor: default;

        &:hover {
            background: #f6f6f6;
            color: $brand-copy-grey;
        }
    }

    &.selected,
    &:hover {
        color: $brand-white;
        background: $brand-blue;
    }

    &.no {
        margin-left: 5px;
    }
}

label > div.toggle-wrapper {
    display: inline-block;
    margin-left: 20px;
}

/* checkbox */
.checkbox-container {
    i {
        color: $brand-input-border;
        padding-right: 15px;
        font-size: 20px;
        vertical-align: middle;

        &.checked {
            color: $brand-blue;
        }
    }
}

mark {
    background: $brand-search-highlight;
}

/* alerts and notices */
.alert {
    max-width: 1160px;
    margin: 50px auto;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 15px;
    h2 {
        font-size: 20px;
        line-height: 24px;
    }
    p {
        font-size: 16px;
        line-height: 20px;
    }
    .help-link {
        text-align: center;
        margin: 30px 0 15px 0;
        a {
            font-weight: bold;
            text-decoration: underline;
            line-height: 0;
            img {
                vertical-align: middle;
                padding: 0 4px 0 0;
            }
            span {
                vertical-align: middle;
            }
        }
    }
}
.alert-info {
    background-color: #d9edf7;
    border-color: #bcdff1;
    color: #31708f;
    h2 {
        color: #31708f;
    }
    .help-link {
        a {
            color: #31708f;
        }
    }
}
.empty-list {
    width: 400px;
    margin: 0 auto;
    background-image: url(../../img/empty-list.png);
    background-position: top center;
    background-size: 190px;
    background-repeat: no-repeat;
    padding: 150px 0 30px 0;

    text-align: center;
    p {
        font-size: 1em;
    }

    p:first-of-type {
        font-weight: bold;
    }

    button:last-of-type {
        margin-left: 10px;
    }
}

.react-tooltip {
    max-width: 400px;
    z-index: 99999;
    p {
        line-height: 20px;
    }
}

@property --progress-value {
    syntax: "<integer>";
    inherits: true;
    initial-value: 0;
}

:root {
    --progress-bar-color: #cfd8dc;
    --progress-value-color: #2196f3;
    --progress-empty-color-h: 4.1;
    --progress-empty-color-s: 89.6;
    --progress-empty-color-l: 58.4;
    --progress-filled-color-h: 122.4;
    --progress-filled-color-s: 39.4;
    --progress-filled-color-l: 49.2;
}

progress[value] {
    display: block;
    position: relative;
    appearance: none;
    width: 100%;
    height: 6px;
    border: 0;
    --border-radius: 10px;
    border-radius: var(--border-radius);
    counter-reset: progress var(--progress-value);
    --progress-value-string: counter(progress) "%";
    --progress-max-decimal: calc(var(--value, 0) / var(--max, 0));
    --progress-value-decimal: calc(var(--progress-value, 0) / var(--max, 0));
    @supports selector(::-moz-progress-bar) {
        --progress-value-decimal: calc(var(--value, 0) / var(--max, 0));
    }
    --progress-value-percent: calc(var(--progress-value-decimal) * 100%);
    --progress-value-color: hsl(
        calc(
                (
                        var(--progress-empty-color-h) + (var(--progress-filled-color-h) - var(--progress-empty-color-h)) *
                            var(--progress-value-decimal)
                    ) * 1deg
            )
            calc(
                (
                        var(--progress-empty-color-s) + (var(--progress-filled-color-s) - var(--progress-empty-color-s)) *
                            var(--progress-value-decimal)
                    ) * 1%
            )
            calc(
                (
                        var(--progress-empty-color-l) + (var(--progress-filled-color-l) - var(--progress-empty-color-l)) *
                            var(--progress-value-decimal)
                    ) * 1%
            )
    );
    animation: calc(3s * var(--progress-max-decimal)) linear 0.5s 1 normal both progress;
}

progress[value]::-webkit-progress-bar {
    background-color: var(--progress-bar-color);
    border-radius: var(--border-radius);
    overflow: hidden;
}

progress[value]::-webkit-progress-value {
    width: var(--progress-value-percent) !important;
    background-color: var(--progress-value-color);
    border-radius: var(--border-radius);
    transition: width 0.5s;
}

progress[value]::-moz-progress-bar {
    width: var(--progress-value-percent) !important;
    background-color: var(--progress-value-color);
    border-radius: var(--border-radius);
}

progress[value]::after {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 13px;
    position: absolute;
    left: var(--progress-value-percent);
    transition: left 0.5s;

    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--progress-value-color);
    border-radius: 4px;
    content: attr(value);
    content: var(--progress-value-string, var(--value));
    font-size: 11px;
    font-weight: normal;
    color: #fff;
}

@keyframes progress {
    from {
        --progress-value: 0;
    }
    to {
        --progress-value: var(--value);
    }
}
