﻿@import "brand-variables.scss";

.tab-strip {
    list-style: none;
    margin: 12px 0 0 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0 2px;
        padding: 6px 12px;
        position: relative;
        vertical-align: bottom;
        transition: padding 0.2s ease;

        border: solid 2px #f5f5f5;
        color: $brand-blue;
        @include border-radius-specific(4px, 4px, 0, 0);
        cursor: pointer;

        &:hover,
        &:active,
        &:focus,
        &.selected {
            outline: none;
            padding: 10px 12px;
        }

        &.disabled {
            cursor: default;
            background-color: #f5f5f5;
            color: $brand-copy-grey;
            padding: 6px 12px;
        }

        &.selected {
            background-color: $brand-blue;
            border-color: $brand-blue;
            color: $brand-white;
            cursor: default;
        }
    }
}

.tab-page {
    padding: 20px 0;
    clear: both;
}

section.tab-section.hidden {
    display: none;
}
