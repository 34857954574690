body {
    &::before {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1; // hide images
        content: url("../../img/tail.svg"); // preload tail image
    }
}

.loader {
    margin: auto;
    width: 150px;
    height: 150px;
    background: url("../../img/tail.svg") no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        background-image: url("../../img/tail-ns.gif");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
    }

    @supports (-ms-accelerator: true) {
        /* IE Edge 12+ CSS */
        background-image: url("../../img/tail-ns.gif");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
    }

    @supports (-ms-ime-align: auto) {
        /* IE Edge 16+ CSS */
        background-image: url("../../img/tail-ns.gif");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
    }
}
