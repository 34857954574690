@import "brand-variables";

.search-container {
    position: relative;
    line-height: 31px;
    background: #fff;
    @include border-radius(3px);
    width: 100%;
    max-width: 284px;
    padding: 0;
    display: inline-block;
    border: solid 2px #dde3e8;
}

.user-search-container{
    display: flex;
    max-width: 600px;

    * + * {
        margin-left: 20px;
    }

    margin-bottom: 10px;
}

.search-container.disabled {
    background: #DDE3E8;
    color: #707070;
}

.search-container > i {
    color: #535353;
    vertical-align: middle;
    padding: 0 10px;
}

.search-container .clear-search {
    position: absolute;
    right: 5px;
    top: -1px;
}

.search-container .clear-search > i {
    font-size: 14px;
    cursor: pointer;
    vertical-align: middle;
    display: none;
    color: $brand-dark-blue;
}

.search-container:hover .clear-search > i {
    display: inline-block;
}

.search-container .search {
    width: 100%;
    border: none;
    padding-left: 20px;
    font-size: 14px;
    color: #3d3d3d;
    max-width: none;
    background: none;
    outline: none;
}

.search-container .search::-ms-clear{
    display: none;
    width:0;
    height:0;
}

.search-container.grey {
    background-color: #eee;
}