﻿@import "brand-variables.scss";

footer.page {
    height: 30px;
    min-height: 30px;
    padding: 20px;

    .layout-content {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}

footer.page > div > a,
footer.page > div > a:hover,
footer.page > div > a:visited,
footer.page > div > span {
    color: $brand-white;
    text-decoration: none;
    padding: 10px 20px 10px 40px;
    font-weight: 300;
    float: right;
    background: none;
    font-size: 13px;
}

footer.page div.language-wrapper {
    display: none;
    padding: 20px;
    height: 60px;
    width: 300px;
    border: solid 1px $brand-input-border;
    background: $brand-white;
    position: absolute;
    right: 5px;
    bottom: 70px;

    select {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }

    img {
    }
}

footer.page div.language-wrapper.show {
    display: block;
}

footer #whatsnew-wrapper {
    display: inline-block;
    width: 120px;
    float: right;
}

#whatsnew-wrapper a {
    padding: 9px 0;
}

footer #HW_badge_cont {
    top: 3px;
    width: 100%;
    padding-left: 32px;
    line-height: 31px;
}

footer #HW_badge_cont:before {
    content: "What's New";
    color: #fff;
}

footer {
    .upgrade-modal-content {
        background: $brand-white;
        z-index: 2147483000;
        position: fixed;
        bottom: 20px;
        right: 20px;
        color: $brand-copy-black;
        height: 210px;
        width: 376px;
        box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
        border-radius: 8px;

        header {
            position: relative;
            min-height: 75px;
            height: 80px;
            background: linear-gradient(135deg, rgb(242, 89, 75), rgb(200, 30, 14));
            color: #fff;
            @include border-radius-specific(8px, 8px, 0, 0);

            i {
                font-size: 20px;
                margin: 30px 20px 0 0;
                float: right;
                cursor: pointer;
            }

            img {
                width: 150px;
                margin: 23px 0 0 25px;
            }
        }

        p,
        a,
        a:hover,
        a:visited {
            color: $brand-copy-black;
            text-decoration: none;
            margin: 0;
        }

        .upgrade-modal-body {
            padding: 40px;
            text-align: center;
        }
    }
}
